<template>
  <div class="uploadFile">
    <div v-if="!isPreview && !successful">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :headers="myHeaders"
        :action="actionUrl"
        :on-success="handleInfoSuccess"
        :on-remove="handleRemove"
        :on-exceed="handleFileExceed"
        :before-upload="beforeAvatarUpload"
        :file-list="fileInfoList"
        :limit="1"
      >
        <i
          class="el-icon-upload"
          style="font-size: 60px; color: #999; margin: 40px 0 5px"
        ></i>
        <div class="el-upload__text">
          <!-- 将文件拖到此处，或 -->
          <span
            class="tabHref"
            style="
              font-size: 18px;
              display: block;
              margin-bottom: 20px;
              font-weight: 600;
              color: #333;
            "
            >点击上传</span
          >
          <div
            slot="tip"
            style="
              margin-top: -5px;
              font-size: 14px;
              line-height: 1.5;
              color: #999;
              padding: 0 20px;
            "
          >
            简历建议PDF文件，也支持docx，doc，jpg，png， 大小不超过20M
          </div>
        </div>
      </el-upload>
    </div>
    <div v-if="isPreview">
      <div style="height: 80%">
        <iframe
          v-if="href"
          id="frame"
          ref="frame"
          frameborder="0"
          scrolling="no"
          style="border: 0"
          width="100%"
          :height="height"
          :src="href"
        ></iframe>
      </div>
      <el-button type="primary" @click="upload">确认上传</el-button>
    </div>
    <div v-if="successful" class="successMsg">
      <i class="iconfont el-icon-success icon"></i>
      <p>简历保存成功</p>
      <span>感谢您的信任与支持</span>
    </div>
  </div>
</template>

<script>
import { ApiBaseUrl, PreviewUrl } from "@/api/http";
import {
  candidateOperateUploadResume, //上传更新简历
} from "../../api/html";
export default {
  data() {
    return {
      actionUrl: ApiBaseUrl + "/candidate/operate/update-resume-by-file",
      myHeaders: {
        access: "CNJH7A347D791K2FCREMYFTG1STJ6T",
      },
      fileInfoList: [],
      isPreview: false,
      href: "",
      successful: false,
      fileId: "",
      height: "",
      //    "https://view.officeapps.live.com/op/view.aspx?src=http://yd-uat.casearching.com/pics/resumeAttachment/15fbb969-f3c9-4303-bebe-1c436d4c196f.docx",
      //   'https://preview.belloai.com/bl/preview?file=http%3A%2F%2Fbelloresume.oss-cn-shanghai.aliyuncs.com%2F62c68f380d77e30001c9f346%252F%25E6%259D%25A8%25E6%2596%2587%25E4%25BD%25B3%2520%25E7%25AE%2580%25E5%258E%2586%2520%25E4%25B8%25AD%25E6%2596%2587%252006.13.pdf%3FOSSAccessKeyId%3DLTAI4GEE6jh9Wfxz7HN9ocYK%26Expires%3D1668477702%26Signature%3DfpKDIWtrDu%252B0wpyP40geyj3UQjw%253D'
    };
  },
  mounted() {
    this.actionUrl =
      ApiBaseUrl +
      "/candidate/operate/update-resume-by-file/" +
      this.$route.query.rid;
    // resetRem
  },
  methods: {
    handleRemove(file, fileList) {
      for (let i in this.fileInfoList) {
        if (this.fileInfoList[i].url == file.url) {
          this.fileInfoList.splice(i, 1);
          break;
        }
      }
      this.$emit("getList", this.fileInfoList);
    },
    handleFileExceed() {
      this.$message.error("最多上传1份文件");
    },
    handleInfoSuccess: function (res, file, fileList) {
      console.log(res, file);
      if (res.success) {
        this.successful = true;
        this.isPreview = false;
      }
      // this.fileId = file.response.result.fileId;
      // this.isPreview = true;
      // this.href = file.response.result.filePreviewUrl;
      // this.initIframe();
      //   this.fileInfoList.push({
      //     name: file.name,
      //     url: file.response.result,
      //   });
      //   candidateOperateUploadFile({ file: this.fileInfoList }).then((res) => {
      //     if (res.success) {
      //       console.log(res);
      //     }
      //   });
      //   this.$emit("getList", this.fileInfoList);
    },
    initIframe() {
      // this.$nextTick(()=>{
      //     this.height=this.$refs.frame.contentWindow.document.body.scrollHeight
      // })
      this.height = document.documentElement.clientHeight - 100;
      console.log(this.height);
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 20;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt10M;
    },
    preOrdown(item) {
      let href = "";
      const fileType =
        item.fileName.split(".")[item.fileName.split(".").length - 1];
      if (
        fileType == "doc" ||
        fileType == "docx" ||
        fileType == "xls" ||
        fileType == "xlsx" ||
        fileType == "pptx" ||
        fileType == "ppt"
      ) {
        href =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          PreviewUrl +
          item.fileUrl;
        //wps预览
        // window.open(
        //   "https://view.officeapps.live.com/op/view.aspx?src=" +
        //     PreviewUrl +
        //     item.fileUrl,
        //   "_blank"
        // );
      } else if (fileType == "pdf") {
        href = "/pdf/web/viewer.html?file=" + PreviewUrl + item.fileUrl;
        //pdf预览
        // window.open(
        //   "/pdf/web/viewer.html?file=" + PreviewUrl + item.fileUrl,
        //   "_blank"
        // );
      } else if (fileType == "png" || fileType == "jpg" || fileType == "jpeg") {
        href = PreviewUrl + item.fileUrl;
        //图片预览
        // window.open(PreviewUrl + item.fileUrl, "_blank");
      } else if (fileType) {
        //下载
        // this.fileDownload(item);
      }
      this.href = href;
    },
    upload() {
      candidateOperateUploadResume({
        fileId: this.fileId,
        rid: this.$route.query.rid,
      }).then((res) => {
        if (res.success) {
          this.successful = true;
          this.isPreview = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadFile {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .el-button {
    width: 80%;
    margin-top: 10px;
  }
  .successMsg {
    padding-top: 120px;
    text-align: center;
    background: #fff;
    height: 100vh;
    .icon {
      font-size: 80px;
      color: #44c966;
    }
    p {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
      margin-top: 20px;
    }
    span {
      display: block;
      position: absolute;
      bottom: 10%;
      color: #999;
      font-size: 14px;
      left: 50%;
      margin-left: -54px;
    }
  }
}
.upload-demo {
  margin-top: 10%;
}
</style>
<style lang="scss">
.uploadFile {
  .el-uploa {
    width: 100%;
  }
  .el-upload-dragger {
    height: 220px;
    width: auto;
    margin: 0 20px;
  }
}
</style>
